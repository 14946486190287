import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../styles/Pulse.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'; // Import required parts from Chart.js

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Pulse: React.FC = () => {
    const [hashrate, setHashrate] = useState<number>(0);
    const [globalHashrate, setGlobalHashrate] = useState<number>(2000); // Global SHA-256 hashrate (example value)
    const [liquidity, setLiquidity] = useState<number>(0);
    const [tradingVolume, setTradingVolume] = useState<number>(0);
    const [blockHeight, setBlockHeight] = useState<number>(0);
    const [transactionCount, setTransactionCount] = useState<number>(0);
    const [priceData, setPriceData] = useState<number[]>([100, 105, 110, 115, 112, 120, 125, 130]); // Example price data
    const [price, setPrice] = useState<number>(130); // Current price

    const ergonEquilibrium = 120; // Example equilibrium price
    const apy = 15.5; // Example APY for the Ergon-Solana pair

    // Simulating real-time data updates
    useEffect(() => {
        const interval = setInterval(() => {
            const newPrice = price + (Math.random() * 10 - 5); // Simulated price changes
            setPrice(newPrice);
            setPriceData((prevData) => [...prevData.slice(1), newPrice]);
            setHashrate((prev) => prev + Math.random() * 10); // Simulated hashrate change
            setLiquidity((prev) => prev + Math.random() * 100); // Simulated liquidity change
            setTradingVolume((prev) => prev + Math.random() * 500); // Simulated trading volume
            setBlockHeight((prev) => prev + 1); // Simulated block height increment
            setTransactionCount((prev) => prev + Math.floor(Math.random() * 50)); // Simulated transaction count
        }, 3000);

        return () => clearInterval(interval);
    }, [price]);

    // Calculate Barometer Value
    const barometerStatus = () => {
        const difference = price - ergonEquilibrium;
        if (difference > 20) return 'Strong Sell';
        if (difference > 10) return 'Sell';
        if (difference < -20) return 'Strong Buy';
        if (difference < -10) return 'Buy';
        return 'Hold';
    };

    // Calculate Hashrate Percentage for Power Gauge
    const hashratePercentage = (hashrate / globalHashrate) * 100;

    return (
        <div className="pulse-container">
            {/* Header Section */}
            <motion.h1
                className="pulse-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Ergon Pulse: Central Command
            </motion.h1>

            <motion.p
                className="pulse-intro"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                Monitor real-time key metrics of the Ergon ecosystem. Stay updated on network health, liquidity, trading volume, and more.
            </motion.p>

            <motion.div className="divider" />

            {/* Barometer Section */}
            <motion.div
                className="barometer-section"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 1 }}
            >
                <h3>Cycle Barometer</h3>
                <div className={`barometer ${barometerStatus().toLowerCase()}`}>
                    {barometerStatus()}
                </div>
            </motion.div>

            <motion.div className="divider" />

            {/* Power Gauge Section */}
            <motion.div
                className="power-gauge-section"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                <h3>Ergon vs Global Hashrate Power</h3>
                <div className="power-gauge">
                    <div className="power-bar" style={{ width: `${hashratePercentage}%` }} />
                    <p>{hashratePercentage.toFixed(2)}% of Global SHA-256 Hashrate</p>
                </div>
            </motion.div>

            {/* Key Metrics about trading */}
            <motion.div
                className="metrics-grid"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >

                {/* Liquidity Section */}
                <div className="metric-card">
                    <h3>Total Liquidity</h3>
                    <motion.p
                        className="metric-value"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        ${liquidity.toFixed(2)}M
                    </motion.p>
                </div>

                {/* Trading Volume Section */}
                <div className="metric-card">
                    <h3>24h Trading Volume</h3>
                    <motion.p
                        className="metric-value"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        ${tradingVolume.toFixed(2)}K
                    </motion.p>
                </div>


                {/* APY Section */}
                <div className="metric-card">
                    <h3>Ergon-Solana Pair APY</h3>
                    <motion.p
                        className="metric-value"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        {apy.toFixed(2)}%
                    </motion.p>
                </div>
            </motion.div>

            {/* Key Metrics and Trading Stats */}
            <motion.div
                className="metrics-grid"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                {/* Hashrate Section */}
                <div className="metric-card">
                    <h3>Network Hashrate</h3>
                    <motion.p
                        className="metric-value"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        {hashrate.toFixed(2)} TH/s
                    </motion.p>
                </div>


                {/* Block Height Section */}
                <div className="metric-card">
                    <h3>Block Height</h3>
                    <motion.p
                        className="metric-value"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        {blockHeight}
                    </motion.p>
                </div>


            </motion.div>

            <motion.div className="divider" />

            {/* Price Chart Section */}
            <motion.div
                className="price-chart"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7, duration: 1 }}
            >
                <h3>Ergon Price Chart</h3>
                <Line
                    data={{
                        labels: priceData.map((_, i) => `T-${priceData.length - i}`),
                        datasets: [
                            {
                                label: 'Price (USD)',
                                data: priceData,
                                borderColor: '#00ff00',
                                backgroundColor: 'rgba(0, 255, 0, 0.1)',
                                fill: true,
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                },
                                type: 'category', // Registering the 'category' scale
                            },
                            y: {
                                beginAtZero: false,
                            },
                        },
                    }}
                />
            </motion.div>

            <motion.div className="divider" />


        </div>
    );
};

export default Pulse;
