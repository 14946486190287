import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Vision.css';

const Vision: React.FC = () => {
    return (
        <div className="vision-container">
            {/* Main Vision Header */}
            <motion.h1
                className="vision-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Ergon: The Future of Energy-Driven DeFi
            </motion.h1>

            {/* Introduction */}
            <motion.p
                className="vision-intro"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                Welcome to **Ergon**, where energy, chaos, and decentralized finance collide. We�ve redefined DeFi with **Bitcoin�s hashrate** as its backbone. Ergon isn�t just a store of value�it lets you harness **raw energy** to profit from every swing, pump, and cycle. It�s the ultimate game for degens looking to feast on volatility.
            </motion.p>

            {/* Animated Bitcoin Hashrate Visual */}
            <motion.div
                className="bitcoin-hashrate-visual"
                initial={{ opacity: 0, rotate: -180 }}
                animate={{ opacity: 1, rotate: 0 }}
                transition={{ delay: 1, duration: 1.5, ease: 'easeInOut' }}
            >
                <div className="btc-hashrate-ring"></div>
            </motion.div>

            {/* New Standard in Store of Value */}
            <motion.section
                className="vision-section"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.2, duration: 1 }}
            >
                <h2 className="section-title">A New Standard of Value, Fueled by Energy</h2>
                <p>
                    Ergon is driven by **Proportional Proof of Work**, reacting directly to the energy input from Bitcoin miners. Every miner joins the action, pumping the price higher. This is a value that doesn�t just sit around�it�s **dynamic**, powered by the raw energy of the real world.
                </p>
            </motion.section>

            {/* Swallowing Bitcoin Hashrate */}
            <motion.section
                className="vision-section"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.4, duration: 1 }}
            >
                <h2 className="section-title">Swallowing Bitcoin's Hashrate: The Fun Part</h2>
                <p>
                    When Ergon pumps, Bitcoin miners jump in, unknowingly feeding the beast. The more they mine, the bigger the cycle, creating a wave that traders ride for profit. Every surge brings more miners into the fold�it's a **self-feeding machine** powered by chaos.
                </p>
                <motion.div
                    className="swallow-bitcoin-hashrate"
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1.2 }}
                    transition={{ delay: 1.6, duration: 1 }}
                >
                    <div className="hashrate-icon"></div>
                </motion.div>
            </motion.section>

            {/* Energy-Driven DeFi */}
            <motion.section
                className="vision-section"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.6, duration: 1 }}
            >
                <h2 className="section-title">Energy-Driven DeFi: A New Paradigm</h2>
                <p>
                    Energy powers everything in Ergon. Bitcoin miners mint new tokens during surges, keeping supply and demand in balance. With every swing, you have a chance to **capitalize on chaos**. This is DeFi that runs on **pure energy**, where every pulse is a chance to profit.
                </p>
                <motion.div
                    className="energy-pulse"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, scale: [1, 1.2, 1] }}
                    transition={{ repeat: Infinity, duration: 2 }}
                >
                    <div className="energy-pulse-ring"></div>
                </motion.div>
            </motion.section>

            {/* Chaos as Opportunity */}
            <motion.section
                className="vision-section"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.8, duration: 1 }}
            >
                <h2 className="section-title">Turn Chaos into Opportunity</h2>
                <p>
                    Chaos is the fuel that powers Ergon. Where others avoid volatility, Ergon **embraces it**. Every price pump or dump is an opportunity for miners, traders, and liquidity providers to profit. Whether you swing trade or hold, Ergon is your gateway to **endless possibilities**.
                </p>
            </motion.section>

            {/* Closing Call to Action */}
            <motion.p
                className="vision-closing"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 2, duration: 1 }}
            >
                Welcome to the revolution. It�s time to embrace the chaos and turn it into power. Ergon is the future of **energy-fueled DeFi**. Join the degens who know how to play.
            </motion.p>
        </div>
    );
};

export default Vision;
