import React from 'react';
import { motion } from 'framer-motion';
import '../styles/LetsCook.css';

const LetsCook: React.FC = () => {
    return (
        <div className="lets-cook-container">
            <motion.h2
                className="lets-cook-header"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                We've Cooked Hard. Everything is Ready.
            </motion.h2>

            <div className="cook-grid">
                <motion.div
                    className="cook-item"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3, duration: 0.8 }}
                >
                    <div className="icon proportional-pow" />
                    <h3>Proportional PoW Algo</h3>
                    <p>Designed to create oscillations and maintain energetic value stability.</p>
                </motion.div>

                <motion.div
                    className="cook-item"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.5, duration: 0.8 }}
                >
                    <div className="icon bitcoin-codebase" />
                    <h3>Bitcoin Codebase</h3>
                    <p>Implemented as $XRG, ensuring compatibility with all Bitcoin miners.</p>
                </motion.div>

                <motion.div
                    className="cook-item"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.7, duration: 0.8 }}
                >
                    <div className="icon mining-pools" />
                    <h3>Mining Pools</h3>
                    <p>Multiple pools attract profit-chasing Bitcoin miners automatically when price rise.</p>
                </motion.div>

                <motion.div
                    className="cook-item"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.9, duration: 0.8 }}
                >
                    <div className="icon cex-listing" />
                    <h3>Xeggex Listing</h3>
                    <p>Creates liquidity gate for miners and arbitrage opportunities between CEX and AMM.</p>
                </motion.div>

                <motion.div
                    className="cook-item"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 1.1, duration: 0.8 }}
                >
                    <div className="icon ergon-wrapped-token" />
                    <h3>$Ergon</h3>
                    <p>The bridge to bring liquidity to AMMs like Raydium.</p>
                </motion.div>


            </div>

            <motion.button
                className="cta-button"
                whileHover={{ scale: 1.1, boxShadow: '0 0 20px #00ff00, 0 0 40px #00ff00' }}
                whileTap={{ scale: 0.95 }}
            >
                Get Started
            </motion.button>
        </div>
    );
};

export default LetsCook;
