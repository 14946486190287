import React from 'react';
import { motion } from 'framer-motion';
import '../styles/HowItWorks.css'; // Custom CSS for styling

const HowItWorks: React.FC = () => {
    return (
        <div className="how-it-works-container">
            {/* Header Section */}
            <motion.h1
                className="how-it-works-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                How $ERGON Dominates
            </motion.h1>

            <div className="content-grid">
                {/* Section: Welcome to DeFi Chaos */}
                <motion.section
                    className="overview-section"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.5, duration: 1 }}
                >
                    <h2>Welcome to the Oscillation Engine</h2>
                    <p>
                        $ERGON isn�t just another token�it�s built to **shake the market**. Its entire structure is designed to **trigger chaos** and profit. Using Bitcoin�s SHA-256 hashrate, $ERGON pushes miners to mint when the price pumps, while traders swing between waves of profit. This is the **next-level DeFi game**.
                    </p>
                </motion.section>

                {/* Section: Mining the Profit Beast */}
                <motion.section
                    className="how-mining-works"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.7, duration: 1 }}
                >
                    <h2>Mining: Unleash the Beast</h2>
                    <p>
                        When $ERGON pumps, **miners flood the market** with new supply�autoswitching Bitcoin ASICs snap to action, following the profits. Each price surge signals a fresh opportunity for miners to **print money**. The more it pumps, the more you make.
                    </p>
                    <p>
                        With **Proportional PoW**, mining doesn�t just reward the early birds�it rewards those who know **when to strike**. Welcome to the new game where **energy meets profit**.
                    </p>
                </motion.section>

                {/* Section: Trade the Volatility */}
                <motion.section
                    className="how-trading-works"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.9, duration: 1 }}
                >
                    <h2>Trade the Waves, Ride the Chaos</h2>
                    <p>
                        $ERGON is **engineered to oscillate**, making it the **ultimate playground for traders**. When the price cools, miners ease up, allowing the price to rise. But when they mint, a wave of new supply floods the market. This constant back-and-forth creates **limitless swing trade opportunities**. Catch the dips, ride the pumps, and **scalp the chaos**.
                    </p>
                    <p>
                        What makes it even wilder? **Surges in hashrate** don�t just mint more $ERGON�they boost its **global reach**. As Bitcoin miners switch to $ERGON during profit peaks, the market shifts under your feet. The best part? No one really knows when the supply will overtake demand, keeping the volatility sharp and the **profit windows open**.
                    </p>
                    <p>
                        It�s not just about buying low and selling high�it�s about **predicting when the market is about to pop** and capitalizing on the **next wave** of action.
                    </p>
                </motion.section>


                {/* Section: Liquidity = Dominance */}
                <motion.section
                    className="how-liquidity-works"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 1.1, duration: 1 }}
                >
                    <h2>Liquidity = Power</h2>
                    <p>
                        **Get your bags in.** $ERGON thrives on liquidity, and by providing it, you�re not just farming rewards�you�re fueling the engine. Every pump, every dump, every high-volatility trade feeds your **LP fees**, and the system is designed to keep that flow **constant**.
                    </p>
                    <p>
                        The true edge? $ERGON pairs aren�t just about temporary profits�they offer **real value storage** with **truly impermanent losses**. That means as traders fight the market waves, liquidity providers can aim for **massive volumes** without worrying about the usual AMM risk. More volume equals bigger yields. It's a win-win where you take advantage of **degen trading chaos** without getting wrecked.
                    </p>
                    <p>
                        Add liquidity, farm the rewards, and let the market work in your favor. Sit back as traders chase pumps, miners flood the market, and you **profit from the chaos**.
                    </p>
                </motion.section>

            </div>

            {/* Call to Action */}
            <motion.div
                className="how-it-works-cta"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.3, duration: 1 }}
            >
                <h3>Ready to Dive in?</h3>
                <p>
                    You�re not here to play safe. Get in, mine, trade, and provide liquidity. $ERGON is the ultimate **profit engine**.
                </p>
            </motion.div>
        </div>
    );
};

export default HowItWorks;
