import React from 'react';
import { motion } from 'framer-motion';
import '../styles/ContactUs.css';

const ContactUs: React.FC = () => {
    return (
        <div className="contact-container">
            <motion.h2
                className="contact-header"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Connect with us
            </motion.h2>

            <motion.p
                className="contact-description"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                Stay connected with Ergon across all our social platforms. Follow us to keep up with the latest news, updates, and community events.
            </motion.p>

            <div className="social-links">
                <motion.a
                    href="https://x.com/ergonites"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    className="social-link twitter"
                >
                    Twitter
                </motion.a>
                <motion.a
                    href="https://discord.gg/M4M3wehTvN"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    className="social-link discord"
                >
                    Discord
                </motion.a>
                <motion.a
                    href="https://t.me/ergonites"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    className="social-link telegram"
                >
                    Telegram
                </motion.a>
                <motion.a
                    href="https://github.com/Ergon-moe"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    className="social-link github"
                >
                    GitHub
                </motion.a>
                <motion.a
                    href="https://reddit.com/r/ergon"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    className="social-link reddit"
                >
                    Reddit
                </motion.a>
                <motion.a
                    href="https://www.youtube.com/ergon"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    className="social-link youtube"
                >
                    YouTube
                </motion.a>
            </div>
        </div>
    );
};

export default ContactUs;
