// NavBar.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import WalletConnect from './WalletConnect.tsx'; // Import the wallet connect component
import '../styles/NavBar.css';

const Navbar: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-content">
                <div className="navbar-logo">
                    <Link to="/">Ergon</Link>
                </div>

                {/* Hamburger Menu for Mobile */}
                <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            {/* Regular Desktop Navigation */}
            <ul className="navbar-links">
                <li><Link to="/vision">Vision</Link></li>
                <li><Link to="/how-it-works">How It Works</Link></li>
                <li className="play-dropdown">
                    <span className="play-dropdown-trigger">Play</span>
                    <ul className="play-dropdown-menu">
                        <li><Link to="/mine-ergon">Expand The Supply</Link></li>
                        <li><Link to="/trade-ergon">Trade The Oscillation</Link></li>
                        <li><Link to="/provide-liquidity">Provide Liquidity</Link></li>
                        <li><Link to="/hold-ergon">Protect your profits</Link></li>
                        <li><Link to="/code-ergon">Code a new world</Link></li>
                    </ul>
                </li>
            </ul>

            <div className="navbar-buttons">
                <Link to="/bridge" className="bridge-button">Bridge</Link>
                <Link to="/pulse" className="pulse-button">Pulse</Link>

            </div>

            {/* Sidebar for Mobile */}
            <div className={`sidebar ${isMenuOpen ? 'open' : ''}`}>
                <div className="sidebar-close" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                </div>
                <ul className="sidebar-links">
                    <li><Link to="/vision" onClick={toggleMenu}>Vision</Link></li>
                    <li><Link to="/how-it-works" onClick={toggleMenu}>How It Works</Link></li>
                    <li><Link to="/mine-ergon" onClick={toggleMenu}>Expand The Supply</Link></li>
                    <li><Link to="/trade-ergon" onClick={toggleMenu}>Trade The Oscillation</Link></li>
                    <li><Link to="/provide-liquidity" onClick={toggleMenu}>Provide Liquidity</Link></li>
                    <li><Link to="/hold-ergon" onClick={toggleMenu}>Protect your profits</Link></li>
                    <li><Link to="/code-ergon" onClick={toggleMenu}>Code a new world</Link></li>
                </ul>
                <div className="sidebar-buttons">
                    <Link to="/bridge" className="bridge-button" onClick={toggleMenu}>Bridge</Link>
                    <Link to="/pulse" className="pulse-button" onClick={toggleMenu}>Pulse</Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
