import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../styles/FAQ.css';

const faqItems = [
    {
        question: 'WTF is Ergon? Why should I care?',
        answer: 'Ergon is the **ultimate chaos machine**, powered by **Bitcoin’s hashrate**. It’s where miners, traders, and **full-blooded degens** come together to turn **market volatility** into pure profit. Forget the old systems—this is DeFi on **energy steroids**. Profit, power, and chaos. Get ready.'
    },
    {
        question: 'Can Ergon really swallow Bitcoin’s hashrate? Are we talking whale-sized?',
        answer: 'Hell yes. Ergon is designed to **devour Bitcoin’s hashrate** whenever profitability spikes. Miners autoswitch when Ergon gets hot, meaning you’ve got a **whale-sized** surge of hashrate blasting through the ecosystem. More miners = more minting = more chaos. Get in and ride the wave.'
    },
    {
        question: 'I’ve got some hashpower. Can I print money with it?',
        answer: '**Hell yes**! Whether you own hardware or rent it via **NiceHash**, you can **print cash** when Ergon starts surging. It’s like **arbitrage on energy steroids**—you rent, you mine, you profit. Simple. Power meets profit.'
    },
    {
        question: 'How much energy does Ergon use? Am I mining or creating a new sun?',
        answer: 'Ergon leverages **real energy** to drive the chaos. You’re not just mining, you’re powering a **profit-driven machine**. Every surge is like birthing a new star in the financial universe, with your mining hardware serving as the **nuclear reactor**. Welcome to **energy chaos**.'
    },
    {
        question: 'Is Ergon basically a money-printing machine for traders?',
        answer: '**Exactly**. The market oscillations are designed for **degenerate traders** who know how to play the game. Buy low, sell high, or jump into liquidity pools and let the chaos make you richer. The market moves, you profit—it’s the **trader’s playground**.'
    },
    {
        question: 'Can I get rekt holding Ergon long-term, or do I become a degen god?',
        answer: 'Holding Ergon long-term is like signing up for the ultimate **degen gauntlet**. Survive the pumps and dumps, and you’ll emerge a **degen god**. Weaklings will get rekt while you ride the chaos and **collect the spoils**. Only the strong thrive here.'
    },
    {
        question: 'What happens if everyone rushes to mine Ergon at the same time?',
        answer: 'If everyone rushes in, the **hashrate goes nuclear**, and the chaos escalates. Miners will flood in, and the whole system will go wild. But here’s the catch: **no one knows** when the supply will overtake demand. It’s a **high-stakes game**, and only those who can handle the pressure win.'
    },
    {
        question: 'Is Ergon’s liquidity farming rekt-proof? Can I farm without watching the world burn?',
        answer: '**Yes**. Ergon’s **predictable price oscillations** help reduce impermanent loss, making it harder for you to get rekt while farming. It’s like walking through chaos in slow motion—**while everyone else panics, you profit**. Get in, provide liquidity, and let the chaos work for you.'
    },
    {
        question: 'What’s backing Ergon? What’s the deal with $XRG?',
        answer: 'Every $ERGON is **backed by $XRG**, the first PoW Bitcoin fork with proportional rewards. Only 61 $XRG have been mined so far, and that’s just the beginning. $XRG is **not cheap**, and neither is $ERGON. You’re holding a piece of the future.'
    },
    {
        question: 'Won’t all the new supply overwhelm the market during pumps?',
        answer: 'Not really. A standard 100 TH/s ASIC is only making **0.00251400 XRG per day**, even during a surge. Ergon’s **continuous halving** ensures that as the network grows, the rewards decrease to maintain equilibrium. Even when the market pumps hard, the new supply trickles in—**don’t freak out, just profit**.'
    },
    {
        question: 'What’s the estimated equilibrium price for $XRG production?',
        answer: 'The **most efficient miners** are producing $XRG at an estimated **$2500 per XRG**, but some might push this up to **$3500** depending on energy costs and efficiency. The balance between miner profitability and market demand ensures that Ergon maintains its chaotic yet **profitable edge**.'
    }
];

// Shuffle function to randomize the order of the array
const shuffleArray = (array: any[]) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};


const FAQ: React.FC = () => {
    const [shuffledFaqItems, setShuffledFaqItems] = useState(faqItems);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setShuffledFaqItems(shuffleArray(faqItems)); // Shuffle the questions on load
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % shuffledFaqItems.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? shuffledFaqItems.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="faq-container">
            <motion.h2
                className="faq-header"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Ergon Survival Guide
            </motion.h2>

            <div className="faq-slider">
                <motion.div
                    className="faq-item"
                    key={currentIndex}
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                >
                    <h3>{shuffledFaqItems[currentIndex].question}</h3>
                    <p>{shuffledFaqItems[currentIndex].answer}</p>
                </motion.div>

                <div className="faq-navigation">
                    <button className="nav-button prev" onClick={handlePrev}>
                        <span>&larr;</span>
                    </button>
                    <button className="nav-button next" onClick={handleNext}>
                        <span>&rarr;</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
