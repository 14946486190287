import React from 'react';
import { motion } from 'framer-motion';
import '../styles/TellMeMore.css';
interface TellMeMoreProps {
    onLetsCoolClick: () => void;
}

const TellMeMore: React.FC<TellMeMoreProps> = ({ onLetsCoolClick }) => {
    return (
        <div className="tell-me-more-container">
            <motion.div
                className="tell-me-more-content"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                <div className="highlighted-text">
                    <span>1 $ERGON</span> is backed by <span>1 $XRG</span>
                </div>
                <p className="subtext">
                    The first PoW Bitcoin fork with proportional rewards. Only 61 have been mined so far�no one has ever produced or bought a cheap $XRG.
                </p>
                <motion.div
                    className="cta-button"
                    onClick={onLetsCoolClick}
                    whileHover={{ scale: 1.1, boxShadow: '0 0 20px #ff33ff, 0 0 40px #ff33ff' }}
                    whileTap={{ scale: 0.95 }}
                >
                    Let's Cook
                </motion.div>
            </motion.div>
        </div>
    );
};

export default TellMeMore;
