import React, { useEffect, useState } from 'react';
import '../styles/Home.css'; // Make sure to create CSS animations and cypherpunk styles in Home.css
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

interface HomeProps {
    onWhatIsErgonClick: () => void; // Updated prop for scrolling to "What Is Ergon"
}

const Home: React.FC<HomeProps> = ({ onWhatIsErgonClick }) => {
    const [glitchText, setGlitchText] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const contractAddress = 'FQ2wEaEWMCkUNnncUBMmbYG5puxtSgL8w9f7SK1tncYf';

    // Glitch effect generator
    useEffect(() => {
        const text = 'WELCOME TO ERGON';
        let intervalId: NodeJS.Timeout;

        const glitchTextEffect = () => {
            const glitchChars = '!@#$%^&*()_+=-[]{}<>?';
            const randomGlitch = text
                .split('')
                .map((char) =>
                    Math.random() > 0.7
                        ? glitchChars[Math.floor(Math.random() * glitchChars.length)]
                        : char
                )
                .join('');
            setGlitchText(randomGlitch);
        };

        intervalId = setInterval(glitchTextEffect, 100);
        return () => clearInterval(intervalId);
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(contractAddress).then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
        });
    };

    return (
        <div className="home-container">
            {/* Background Animation */}
            <div className="cypher-background">
                <motion.div
                    className="cypher-lines"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, y: [-100, 0] }}
                    transition={{ duration: 2 }}
                />
                <motion.div
                    className="cypher-noise"
                    initial={{ opacity: 0.1 }}
                    animate={{ opacity: 0.5, scale: [1, 1.2] }}
                    transition={{ repeat: Infinity, duration: 5, repeatType: 'reverse' }}
                />
            </div>

            {/* Main Header */}
            <motion.h1
                className="glitch-header"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, y: [50, 0] }}
                transition={{ duration: 1.5, ease: 'easeInOut' }}
            >
                {glitchText}
            </motion.h1>

            {/* Subheader */}
            <motion.p
                className="cypher-subtext"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, y: [50, 0] }}
                transition={{ delay: 1.5, duration: 1.5 }}
            >
                Ride the chaos of decentralized energy. Profit from the game of Ergon.
            </motion.p>

            {/* Contract Address with Copy Icon */}
            <motion.div
                className="contract-address-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, y: [20, 0] }}
                transition={{ delay: 2.5, duration: 1.5 }}
            >
                <span className="contract-address">
                    CA: {contractAddress}
                </span>
                <FontAwesomeIcon
                    icon={faCopy}
                    className="copy-icon"
                    onClick={handleCopy}
                    title="Copy to Clipboard"
                />
                {copySuccess && <span className="copy-success">Copied!</span>}
            </motion.div>

            {/* Call to Action */}
            <motion.div
                className="cta-button-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2, duration: 1 }}
            >
                <motion.div
                    className="cta-button"
                    onClick={onWhatIsErgonClick} // Trigger scroll to What Is Ergon section
                    whileHover={{ scale: 1.1, boxShadow: '0 0 20px #ff33ff, 0 0 40px #ff33ff' }}
                    whileTap={{ scale: 0.95 }}
                    style={{ cursor: 'pointer' }}
                >
                    What Is Ergon?
                </motion.div>
            </motion.div>


        </div>
    );
};

export default Home;
