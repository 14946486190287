"use client";

import React, { useMemo } from "react";
import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import '@solana/wallet-adapter-react-ui/styles.css';

export const AppWalletProvider = ({ children }: { children: React.ReactNode }) => {
    const network = "mainnet-beta"; // Solana mainnet identifier
    const quickNodeEndpoint = "https://chaotic-crimson-star.solana-mainnet.quiknode.pro/a7cdf02f65bc61c5443446e5db4ea0fda4cd455e/";

    // Use QuickNode endpoint instead of the default clusterApiUrl
    const endpoint = useMemo(() => quickNodeEndpoint, [network]);

    const wallets = useMemo(() => [
        // Add any other wallet adapters here (e.g., Phantom, Sollet, etc.)
    ], [network]);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
