import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/GetStarted.css'; // Updated CSS for improved layout

const GetStarted: React.FC = () => {
    return (
        <div className="get-started-join-game-container" id="get-started">
            {/* Main Header */}
            <motion.h2
                className="get-started-header"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Ready to Ride the $Ergon Wave?
            </motion.h2>

            {/* Description */}
            <motion.p
                className="get-started-description"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                Join $Ergon, where we **swallow Bitcoin�s hashrate** and **turn raw energy into profit**. It�s not just about mining�it�s about **breaking the system** and having fun.
            </motion.p>


            {/* Steps Section with Grid */}
            <motion.div
                className="get-started-grid"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                {/* Step 1: Print Money from the Chaos */}
                <div className="step">
                    <div className="step-icon mining-icon"></div>
                    <h3>Print Money from the Chaos</h3>
                    <p>Fire up your rigs and flood the market with Ergon when the price pumps. You�re the supply now.</p>
                    <Link to="/mine-ergon" className="cta-button">
                        Start Printing
                    </Link>
                </div>

                {/* Step 2: Surf the Oscillations */}
                <div className="step">
                    <div className="step-icon trading-icon"></div>
                    <h3>Surf the Oscillations</h3>
                    <p>Ride the Ergon waves like a degenerate whale�buy low, sell high, rinse, repeat. The swings are your playground.</p>
                    <Link to="/trade-ergon" className="cta-button">
                        Trade the Chaos
                    </Link>
                </div>

                {/* Step 3: Stack Rewards on LP */}
                <div className="step">
                    <div className="step-icon liquidity-icon"></div>
                    <h3>Stack Rewards on LP</h3>
                    <p>Earn mad returns while others sleep. Stake your liquidity, stack those fees, and minimize impermanent loss like a pro.</p>
                    <Link to="/provide-liquidity" className="cta-button">
                        Stack Rewards
                    </Link>
                </div>

                {/* Step 4: Fortify the Bag */}
                <div className="step">
                    <div className="step-icon protect-icon"></div>
                    <h3>Fortify the Bag</h3>
                    <p>Lock in your gains and hold tight. Ergon�s adaptive store of value will defend your profits in any storm.</p>
                    <Link to="/hold-ergon" className="cta-button">
                        Fortify Now
                    </Link>
                </div>

            </motion.div>

        </div>
    );
};

export default GetStarted;
