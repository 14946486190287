import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx'; // Main App component

// Find the root div in your index.html
const rootElement = document.getElementById('root');

// Create a root for the app
const root = ReactDOM.createRoot(rootElement!);

// Render the App component inside the root div
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
