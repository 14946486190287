import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/MineErgon.css'; // Add custom styling

const MineErgon: React.FC = () => {
    return (
        <div className="mine-ergon-container">
            {/* Header Section */}
            <motion.div
                className="mine-ergon-header-container"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                <h1 className="mine-ergon-header">
                    Mine $ERGON, Dominate the Hashrate
                </h1>
                <p className="mine-ergon-subtitle">
                    Autoswitching farms follow profits. Be ahead of the curve.
                </p>
            </motion.div>

            {/* Key Features Section */}
            <motion.div
                className="mine-ergon-features"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <div className="feature-grid">
                    <div className="feature-item">
                        <div className="feature-icon asic-icon"></div>
                        <h3>ASIC Mining Power</h3>
                        <p>Turn your Bitcoin ASICs loose on $ERGON with the same SHA-256 power.</p>
                    </div>
                    <div className="feature-item">
                        <div className="feature-icon surge-icon"></div>
                        <h3>Profit on Surges</h3>
                        <p>Exploit market price surges and mine when demand is at its peak.</p>
                    </div>
                    <div className="feature-item">
                        <div className="feature-icon bridge-icon"></div>
                        <h3>Bridge to $ERGON</h3>
                        <p>Swap mined $XRG to $ERGON on Solana for liquidity or yield.</p>
                    </div>
                    <div className="feature-item">
                        <div className="feature-icon nicehash-icon"></div>
                        <h3>NiceHash Arbitrage</h3>
                        <p>Rent hashpower during surges and make killer arbitrage moves.</p>
                    </div>
                </div>
            </motion.div>

            <motion.div className="divider" />

            {/* Why Mining Matters Section */}
            <motion.section
                className="why-mine-ergon"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7, duration: 1 }}
            >
                <h2>Why Mining $ERGON Matters</h2>
                <div className="why-mine-content">
                    <p>
                        The $ERGON ecosystem is primed for domination. Here�s why mining $ERGON could be your best bet:
                    </p>
                    <ul className="why-mine-list">
                        <li><strong>Automated Profit Shifts:</strong> Big farms autoswitch to $ERGON when it becomes the most profitable. You�re riding a wave of hashpower.</li>
                        <li><strong>Maximize Surges:</strong> Mine during price pumps, and convert your rewards into liquidity with $ERGON wrapped tokens.</li>
                        <li><strong>Use NiceHash:</strong> Rent hashpower when $ERGON hits profitability spikes to make quick profits.</li>
                    </ul>
                </div>
            </motion.section>

            <motion.div className="divider" />

            {/* Mining Steps Section */}
            <motion.section
                className="mining-steps"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.9, duration: 1 }}
            >
                <h2>How to Start Mining</h2>
                <div className="steps-grid">
                    <div className="step">
                        <h3>1. Set Up Your ASIC</h3>
                        <p>Make sure your miner is configured for SHA-256 to mint $ERGON.</p>
                    </div>
                    <div className="step">
                        <h3>2. Choose a Pool</h3>
                        <p>Visit <a href="https://miningpoolstats.stream/ergon" target="_blank" rel="noopener noreferrer">MiningPoolStats</a> or join the <a href="https://pool.ergon.network/" target="_blank" rel="noopener noreferrer">Official Ergon Pool</a>.</p>
                    </div>
                    <div className="step">
                        <h3>3. Start Mining</h3>
                        <p>Earn $XRG rewards and convert to $ERGON for liquidity and yield farming.</p>
                    </div>
                    <div className="step">
                        <h3>4. Profit & Swap</h3>
                        <p>Use the <Link to="/bridge">Ergon Bridge</Link> to swap mined $XRG to $ERGON and chase liquidity.</p>
                    </div>
                </div>
            </motion.section>

            <motion.div className="divider" />

            {/* Call to Action Section */}
            <motion.div
                className="mine-ergon-cta"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                <h3>Let�s Take Over the Hashrate</h3>
                <p>Whether you�re running an ASIC farm or renting power from NiceHash, the time to act is now.</p>
                <div className="cta-buttons">
                    <a href="https://pool.ergon.network/" target="_blank" rel="noopener noreferrer" className="cta-button">Join the Official Pool</a>
                    <Link to="/bridge" className="cta-button">Use the Ergon Bridge</Link>
                </div>
            </motion.div>
        </div>
    );
};

export default MineErgon;
