import React, { useEffect, useRef } from 'react';
import { Chart, LineController, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import '../styles/AnimatedChart.css'; // Custom CSS for styling

// Register necessary components for Chart.js
Chart.register(LineController, LineElement, CategoryScale, LinearScale, PointElement, Tooltip);

const AnimatedChart: React.FC = () => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    let animationFrameId: number;

    // Fixed dataset simulating price oscillation between $200 and $20,000
    const priceData = [
        3000, 3100, 2900, 3050, 2950, 3020, 2980, 3000, 2950, 3050, 3000, 2900, 2850, 2750, 2800, 2700, 3000, 3200,
        3300, 3400, 3100, 3000, 2950, 3100, 2850, 2800, 3000, 3100, 3200, 3050, 2900, 3000, 2950, 2800, 2900, 2700,
        2500, 2600, 2550, 3000, 3100, 3200, 3150, 3100, 3000, 2950, 3100, 3300, 3100, 3000, 2850, 2950, 2800, 2700,
        3000, 3100, 2900, 2850, 2750, 3000, 3100, 3500, 4000, 3700, 3400, 3100, 3000, 2950, 3100, 2900, 3100, 2950,
        3000, 2800, 2750, 2650, 2600, 3000, 3200, 2900, 2750, 3000, 3100, 3500, 4000, 5000, 4700, 4000, 3600, 3300,
        3100, 3000, 2900, 3200, 3400, 3100, 3000, 2900, 2700, 3000, 3500, 3700, 4000, 5000, 6000, 7000, 7500, 6000,
        5000, 4200, 3700, 3000, 2500, 2000, 2700, 3000, 3300, 3600, 3900, 3000, 2800, 2600, 2400, 2200, 2100, 3000,
        3100, 2900, 3050, 2950, 3020, 2980, 3000, 2950, 3050, 3000, 2900, 2850, 2750, 2800, 2700, 3000, 3200, 3300,
        3400, 3100, 3000, 2950, 3100, 2850, 2800, 3000, 3100, 3200, 3050, 2900, 3000, 2950, 2800, 2900, 2700, 2500,
        2600, 2550, 3000, 3100, 3200, 3150, 3100, 3000, 2950, 3100, 3300, 3100, 3000, 2850, 2950, 2800, 2700, 3000,
        3100, 2900, 2850, 2750, 3000, 3100, 3500, 4000, 3700, 3400, 3100, 3000, 2950, 3100, 2900, 3100, 2950, 3000,
        2800, 2750, 2650, 2600, 3000, 3200, 2900, 2750, 3000, 3100, 3500, 4000, 5000, 4700, 4000, 3600, 3300, 3100,
        3000, 2900, 3200, 3400, 3100, 3000, 2900, 2700, 3000, 3500, 3700, 4000, 5000, 6000, 7000, 7500, 6000, 5000,
        4200, 3700, 3000, 2500, 2000, 2700, 3000, 3300, 3600, 3900, 3000, 2800, 2600, 2400, 2200, 2100, 3000, 3200,
        3300, 3400, 3000, 2950, 2800, 3000, 3300, 3500, 3700, 4200, 5000, 6000, 8000, 10000, 8000, 7000, 6000, 5000,
        4000, 3000, 3500, 5000, 7000, 9000, 12000, 11000, 10000, 8000, 7000, 6000, 5000, 3000
    ];



    // Time lag configuration: how much delay to apply for hashrate reaction (e.g., 5 steps)
    const timeLag = 10; // 5 data points behind

    // Function to simulate hashrate reactivity with a time lag
    const generateHashrateDataWithLag = (priceData: number[], timeLag: number) => {
        const hashrateData = [];
        for (let i = 0; i < priceData.length; i++) {
            const delayedPrice = i >= timeLag ? priceData[i - timeLag] : priceData[0]; // Apply time lag for hashrate reaction
            const reactivity = Math.max(0, delayedPrice - 2500) / 20000; // Normalize price to $20,000 max for scaling
            const baseHashrate = 100 + reactivity * 24900; // Base hashrate between 100TH/s and 20PH/s
            hashrateData.push(baseHashrate);
        }
        return hashrateData;
    };

    // Generate the hashrate data with a 5-point time lag
    const hashrateData = generateHashrateDataWithLag(priceData, timeLag);



    // Initialize the chart after the component renders
    useEffect(() => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');

            const chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: Array(priceData.length).fill(''), // Empty labels to simulate scrolling
                    datasets: [
                        {
                            label: 'Price (USD)',
                            data: priceData,
                            borderColor: '#00ff00',
                            backgroundColor: 'rgba(0, 255, 0, 0.1)',
                            borderWidth: 2,
                            fill: false,
                            tension: 0.4, // Smooth interlacing
                        },
                        {
                            label: 'Hashrate (PH/s)',
                            data: hashrateData,
                            borderColor: '#ff33ff',
                            backgroundColor: 'rgba(255, 51, 255, 0.1)',
                            borderWidth: 2,
                            fill: false,
                            tension: 0.4, // Smooth interlacing
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false, // Allow dynamic resizing
                    animation: {
                        duration: 4000, // Slow, smooth animation speed
                        easing: 'easeInOutSine', // Smooth easing for natural flow
                    },
                    scales: {
                        x: {
                            display: false, // Hide x-axis
                        },
                        y: {
                            beginAtZero: false,
                            grid: {
                                display: false,
                            },
                            ticks: {
                                display: false, // Hide y-axis ticks
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Hide legend
                        },
                        tooltip: {
                            enabled: false, // Hide tooltips
                        },
                    },
                },
            });

            // Function to update chart data continuously like an oscilloscope
            const updateChart = () => {
                chart.data.labels.push('');
                chart.data.datasets.forEach((dataset) => {
                    dataset.data.push(dataset.data.shift() as number); // Shift data for infinite scrolling
                });
                chart.update('none'); // No animation during update for smooth scrolling

                animationFrameId = requestAnimationFrame(updateChart);
            };

            // Start updating chart data immediately
            animationFrameId = requestAnimationFrame(updateChart);

            // Cleanup chart instance on component unmount
            return () => {
                cancelAnimationFrame(animationFrameId);
                chart.destroy();
            };
        }
    }, []);

    return (
        <div className="chart-container">
            <canvas ref={chartRef}></canvas>
            <div className="chart-labels">
                <div className="price-label">Price</div>
                <div className="hashrate-label">Hashrate</div>
            </div>
        </div>
    );
};

export default AnimatedChart;
