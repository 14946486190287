import React, { useState, useEffect } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import '../styles/WalletConnect.css';

const ERGON_MINT_ADDRESS = new PublicKey('FQ2wEaEWMCkUNnncUBMmbYG5puxtSgL8w9f7SK1tncYf'); // Ensure ERGON mint address is correct

const WalletConnect: React.FC = () => {
    const { publicKey } = useWallet();
    const { connection } = useConnection();
    const [ergonBalance, setErgonBalance] = useState<number>(0);
    const [solBalance, setSolBalance] = useState<number>(0);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (publicKey) {
            fetchBalances(); // Initial fetch when the wallet connects
            const balanceInterval = setInterval(fetchBalances, 120000); // Fetch balances every 5 seconds
            return () => clearInterval(balanceInterval); // Cleanup interval on component unmount
        }
    }, [publicKey]);

    const fetchBalances = async () => {
        await fetchSolBalance();
        await fetchErgonBalance();
    };

    const fetchSolBalance = async () => {
        try {
            if (!publicKey) return;
            const solBalanceLamports = await connection.getBalance(publicKey);
            // console.log('Sol Balance (Lamports):', solBalanceLamports);
            setSolBalance(solBalanceLamports / LAMPORTS_PER_SOL);
        } catch (error) {
            console.error('Error fetching SOL balance:', error);
        }
    };

    const fetchErgonBalance = async () => {
        try {
            if (!publicKey) return;

            // Fetch all token accounts owned by the publicKey
            const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
                publicKey,
                { programId: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA') } // Solana token program
            );

            //console.log('Token Accounts:', tokenAccounts); // Log token accounts

            // Find the ERGON token account by matching the mint address
            const ergonAccount = tokenAccounts.value.find(
                (account) => account.account.data.parsed.info.mint === ERGON_MINT_ADDRESS.toString()
            );

            //console.log('Ergon Account:', ergonAccount); // Log ERGON token account

            if (ergonAccount) {
                const ergonAmount = ergonAccount.account.data.parsed.info.tokenAmount.uiAmount;
                setErgonBalance(ergonAmount);
            } else {
                setErgonBalance(0); // Set to zero if no account found
            }
        } catch (error) {
            console.error('Error fetching ERGON balance:', error);
        }
    };

    return (
        <div
            className="wallet-connect"
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}
        >
            {publicKey ? (
                <div className="wallet-connected">
                    <span className="wallet-address">
                        {publicKey.toBase58().slice(0, 4)}...{publicKey.toBase58().slice(-4)}
                    </span>

                    {menuOpen && (
                        <div className="wallet-menu">
                            <div className="wallet-balance">
                                <p>$ERGON: {ergonBalance.toFixed(4)}</p>
                                <p>$SOL: {solBalance.toFixed(4)}</p>
                            </div>
                            <WalletDisconnectButton className="disconnect-button" />
                        </div>
                    )}
                </div>
            ) : (
                <WalletMultiButton className="wallet-connect-button" />
            )}
        </div>
    );
};

export default WalletConnect;
