import React from 'react';
import { motion } from 'framer-motion';
import '../styles/TradeErgon.css';

const TradeErgon: React.FC = () => {
    return (
        <div className="trade-ergon-container">
            {/* Header Section */}
            <motion.h1
                className="trade-ergon-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Trade $ERGON, Play the Volatility Game
            </motion.h1>

            {/* Introduction Section */}
            <motion.p
                className="trade-ergon-intro"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                $ERGON is a **trader�s dream**, built to oscillate and create endless opportunities to grab profits. Whether you're scalping quick moves or riding bigger waves, $ERGON lets you play the market like a pro. The price rides on the waves of **Proportional PoW**, and all the indicators you need are live on <a href="/pulse">/pulse</a>�so stay locked in, and trade the chaos.
            </motion.p>

            <motion.div className="divider" />

            {/* Why Trade Ergon */}
            <motion.section
                className="why-trade-ergon"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <h2>Why Trade $ERGON?</h2>
                <p>
                    The market is **predictably volatile**, swinging between mining surges and price cooldowns. Traders who know when to dive in can make insane profits by riding the oscillations. Here�s why $ERGON trading is pure alpha:
                </p>
                <ul>
                    <li><strong>Oscillations Galore:</strong> Mining ramps up when the price spikes, flooding the market with new supply. When miners pause, **demand catches up**, and the price rockets again.</li>
                    <li><strong>Built for Degens:</strong> If you can handle the swings, $ERGON will reward you. Master the waves, and you�ll be farming profits non-stop.</li>
                    <li><strong>Real-Time Intel:</strong> Stay ahead of the market by watching <a href="/pulse">/pulse</a> for key metrics like hashrate, trading volume, and price shifts.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* How to Trade Ergon */}
            <motion.section
                className="how-to-trade-ergon"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7, duration: 1 }}
            >
                <h2>How to Crush $ERGON Trading</h2>
                <p>
                    Timing is everything with $ERGON. Here�s how you can step in and dominate the game:
                </p>
                <ol>
                    <li><strong>Track the Pulse:</strong> Hit up <a href="/pulse">/pulse</a> to see real-time metrics on mining activity, volume, and price oscillations.</li>
                    <li><strong>Buy the Dips:</strong> Grab $ERGON when miners take a breather, and demand starts eating up the supply. **This is where the alpha is.**</li>
                    <li><strong>Sell the Pumps:</strong> When miners flood the market, sell into strength and bank your profits before the next dip.</li>
                    <li><strong>Watch the Mining Cycle:</strong> Use mining activity as a signal. When it pauses, prices rise. When it cranks up, be ready to sell.</li>
                </ol>
            </motion.section>

            <motion.div className="divider" />


            {/* Call to Action */}
            <motion.div
                className="trade-ergon-cta"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                <h3>Let�s Ride the Waves</h3>
                <p>Hop into $ERGON�s market and start trading its wild oscillations. With real-time insights on <a href="/pulse">/pulse</a>, you�re set to ride the chaos.</p>
                <a href="https://xeggex.com/market/XRG_USDT" className="cta-button">Start Trading</a>
            </motion.div>
        </div>
    );
};

export default TradeErgon;
