import React from 'react';
import { motion } from 'framer-motion';
import '../styles/HoldErgon.css';

const HoldErgon: React.FC = () => {
    return (
        <div className="hold-ergon-container">
            {/* Animated Background Waves */}
            <div className="background-waves"></div>

            {/* Header Section */}
            <motion.h1
                className="hold-ergon-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Hold $ERGON: Secure the Future, Ride the Chaos
            </motion.h1>

            {/* Introduction Section */}
            <motion.p
                className="hold-ergon-intro"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                Holding $ERGON is about locking in gains while riding the volatility of the market. **You�re securing your position in the future of decentralized finance**. As the oscillation continues, **your wealth grows**�capitalizing on every pump and holding through every dip.
            </motion.p>

            <motion.div className="divider" />

            {/* Why Hold Ergon */}
            <motion.section
                className="why-hold-ergon"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <div className="why-hold-visual"></div> {/* Decorative visual block */}
                <h2>Why You Should Hold $ERGON</h2>
                <p>
                    Holding $ERGON isn�t just about gains�it�s about **winning the long game**. Here�s why holding matters:
                </p>
                <ul className="why-hold-list">
                    <li><strong>Price Stability:</strong> $ERGON�s dynamic market mechanics ensure predictable cycles, giving you a long-term hedge against chaos.</li>
                    <li><strong>Profit from Surges:</strong> When miners mint new $ERGON tokens during price hikes, you�re in prime position to capitalize.</li>
                    <li><strong>Power in Numbers:</strong> Holding means you become an integral part of the network, prepared for future staking and lending opportunities.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* Holding Benefits */}
            <motion.section
                className="holding-benefits"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7, duration: 1 }}
            >
                <div className="benefits-grid">
                    <div className="benefit-item">
                        <div className="benefit-icon"></div> {/* Custom CSS visual */}
                        <h3>Price Stability</h3>
                        <p>$ERGON creates predictable market cycles, protecting your investment against massive swings.</p>
                    </div>
                    <div className="benefit-item">
                        <div className="benefit-icon"></div>
                        <h3>Long-Term Gains</h3>
                        <p>Holding through turbulent periods means you benefit from massive price hikes when the market catches up.</p>
                    </div>
                    <div className="benefit-item">
                        <div className="benefit-icon"></div>
                        <h3>Future Access</h3>
                        <p>Position yourself for future ecosystem tools like staking, lending, and collateralized loans.</p>
                    </div>
                </div>
            </motion.section>

            <motion.div className="divider" />

            {/* Call to Action */}
            <motion.div
                className="hold-ergon-cta"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                <h3>Secure Your Position with $ERGON</h3>
                <p>Don't just trade�**hold** and watch your position grow as Ergon takes over the scene. Lock in your gains now and ride the oscillation.</p>
                <a href="https://calory.social" className="cta-button">Start Holding Now</a>
            </motion.div>
        </div>
    );
};

export default HoldErgon;
