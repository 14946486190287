import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Bridge.css';
import Burner from './Burner.tsx'; // Import the Burner component

const Bridge: React.FC = () => {
    return (
        <div className="bridge-container">
            {/* Header Section */}
            <motion.h1
                className="bridge-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Burn $XRG for $ERGON
            </motion.h1>

            {/* Introduction Section */}
            <motion.p
                className="bridge-intro"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                Power is money, literally. Your $XRG represents raw energy, minted through Proportional PoW.
                This bridge is a one-way ticket to convert that raw energetic value into $ERGON, the DeFi revolution on Solana.
                Burn the energy. Unleash $ERGON.
            </motion.p>

            <motion.div className="divider" />

            {/* Burner Component Section */}
            <motion.div
                className="burner-section"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <Burner /> {/* The separate burner component */}
            </motion.div>

            <motion.div className="divider" />

            {/* CTA - Buy XRG */}
            <motion.div
                className="buy-xrg-cta"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8, duration: 1 }}
            >
                <p>
                    Don’t have $XRG yet? <a href="https://xeggex.com/market/XRG_USDT" target="_blank" rel="noopener noreferrer">Buy XRG on Xeggex</a> and start the burn.
                </p>
            </motion.div>

            <motion.div className="divider" />

            {/* Link to Calory Social Wallet */}
            <motion.div
                className="calory-link"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                <p>
                    Need a wallet? <a href="https://calory.social" target="_blank" rel="noopener noreferrer">Get a Calory Wallet</a> and store your $XRG safely.
                </p>
            </motion.div>

            {/* Wallet Reminder */}
            <motion.div
                className="bridge-wallet-reminder"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.3, duration: 1 }}
            >
                <p>
                    After burning, make sure to add $ERGON to your Solana wallet to see your new balance. Welcome to the future.
                </p>
            </motion.div>
        </div>
    );
};

export default Bridge;
