import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Line } from 'react-chartjs-2';
import AnimatedChart from './AnimatedChart.tsx'; // Import the animated chart
import '../styles/WhatIsErgon.css'; // Custom CSS for styling
interface WhatIsErgonProps {
    onTellMeMoreClick: () => void;
}

const textList = [
    'a revolution in decentralized energy',
    'the fusion of Bitcoin and Solana',
    'where DeFi meets real power',
    'the game-changer for crypto mining',
    'an oscillating token built for profit',
    'the power vacuum of Bitcoin hashrate',
    'your gateway to the ultimate DeFi game',
    'pumping with every price swing',
    'the token miners and traders can�t resist',
    'a degenerate�s dream for gains',
    'the new frontier of decentralized finance',
    'profit from chaos and energy',
    'turning hashrate into liquid assets',
    'more than a token, it�s a movement',
    'driven by Bitcoin, powered by you'
];

const WhatIsErgon: React.FC<WhatIsErgonProps> = ({ onTellMeMoreClick }) => {
    const [index, setIndex] = useState(0);
    const [priceData, setPriceData] = useState<number[]>([100, 105, 110, 115, 112, 120, 125]);
    const [hashrateData, setHashrateData] = useState<number[]>([1000, 1050, 1020, 1100, 1080, 1120, 1150]);

    // Change text every 7 seconds
    useEffect(() => {
        const textChangeInterval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % textList.length);
        }, 7000);
        return () => clearInterval(textChangeInterval);
    }, []);

    // Simulate Price and Hashrate Data Updates
    useEffect(() => {
        const dataUpdateInterval = setInterval(() => {
            const newPrice = priceData[priceData.length - 1] + (Math.random() * 10 - 5);
            const newHashrate = hashrateData[hashrateData.length - 1] + (Math.random() * 50 - 25);
            setPriceData((prevData) => [...prevData.slice(1), newPrice]);
            setHashrateData((prevData) => [...prevData.slice(1), newHashrate]);
        }, 3000); // Update data every 3 seconds

        return () => clearInterval(dataUpdateInterval);
    }, [priceData, hashrateData]);

    // Chart Data Configuration
    const chartData = {
        labels: priceData.map((_, i) => `T-${priceData.length - i}`), // Timestamps
        datasets: [
            {
                label: 'Price (USD)',
                data: priceData,
                borderColor: '#00ff00',
                backgroundColor: 'rgba(0, 255, 0, 0.1)',
                fill: true,
            },
            {
                label: 'Hashrate (TH/s)',
                data: hashrateData,
                borderColor: '#ff33ff',
                backgroundColor: 'rgba(255, 51, 255, 0.1)',
                fill: true,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: false,
            },
        },
    };

    return (
        <div className="what-is-ergon-container">
            {/* Static + Dynamic Text on Same Line */}
            <motion.div
                className="ergon-text-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <h1 className="static-text">Ergon is</h1>
                <AnimatePresence mode="wait">
                    <motion.h1
                        key={index}
                        className="dynamic-text"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.8, ease: 'easeInOut' }}
                    >
                        {textList[index]}
                    </motion.h1>
                </AnimatePresence>
            </motion.div>

            {/* Animated Chart Section */}
            <div className="animated-chart-container">
                <AnimatedChart />
            </div>

            {/* Get Started Button */}
            <motion.div
                className="cta-button"
                onClick={onTellMeMoreClick}
                whileHover={{ scale: 1.1, boxShadow: '0 0 20px #33ff33, 0 0 40px #33ff33' }}
                whileTap={{ scale: 0.95 }}
                style={{ cursor: 'pointer', marginTop: '1.5rem' }}
            >
                We're just starting
            </motion.div>
        </div>
    );
};

export default WhatIsErgon;
