import React from 'react';
import { motion } from 'framer-motion';
import '../styles/ProvideLiquidity.css';

const ProvideLiquidity: React.FC = () => {
    return (
        <div className="provide-liquidity-container">
            {/* Animated Background Elements */}
            <div className="background-grid"></div>

            {/* Header Section */}
            <motion.h1
                className="provide-liquidity-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Stake Your Liquidity. Stack Your Rewards.
            </motion.h1>

            {/* Introduction Section */}
            <motion.p
                className="provide-liquidity-intro"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                Ergon liquidity pools are designed to keep the **profit train** rolling. With Ergon�s **Proportional PoW** powering predictable oscillations, liquidity providers can farm the dips and capitalize on high volatility with **fat yields**. This is farming without getting liquidated, **without the bullshit**.
            </motion.p>

            <motion.div className="divider" />

            {/* Why Provide Liquidity */}
            <motion.section
                className="why-provide-liquidity"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <div className="liquidity-icon-container"></div> {/* Decorative visual block */}
                <h2>Why Lock Your Liquidity?</h2>
                <p>
                    In DeFi, **liquidity is power**. By dropping your liquidity into $ERGON pools, you�re not just earning fees�you�re **locking in dominance**:
                </p>
                <ul className="liquidity-benefits-list">
                    <li><strong>Stack Rewards:</strong> Collect fees on every trade�**every pump, every dump**�and watch your holdings skyrocket.</li>
                    <li><strong>Low Impermanent Loss:</strong> Ergon�s predictable swings keep you **farming safely** while others get rekt.</li>
                    <li><strong>Profit from Volatility:</strong> Ergon�s chaos means **more trades, more volume, and more fees** for you.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* How to Provide Liquidity */}
            <motion.section
                className="how-to-provide-liquidity"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7, duration: 1 }}
            >
                <h2>How to Dive In</h2>
                <div className="steps-grid">
                    <div className="step-item">
                        <h3>Step 1</h3>
                        <p><strong>Pick Your DEX:</strong> Go where the liquidity�s at. Make sure $ERGON pools are live.</p>
                    </div>
                    <div className="step-item">
                        <h3>Step 2</h3>
                        <p><strong>Pair and Load:</strong> Add $ERGON liquidity by pairing it with another crypto like SOL. </p>
                    </div>
                    <div className="step-item">
                        <h3>Step 3</h3>
                        <p><strong>Earn While Others Play:</strong> Every trade pays you. You�re banking on every swing.</p>
                    </div>
                    <div className="step-item">
                        <h3>Step 4</h3>
                        <p><strong>Monitor and Adjust:</strong> Keep an eye on your position as **volatility feeds your bag**.</p>
                    </div>
                </div>
            </motion.section>

            <motion.div className="divider" />

            {/* Benefits of Liquidity */}
            <motion.section
                className="liquidity-benefits"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 1 }}
            >
                <h2>Big Liquidity = Big Gains</h2>
                <p>Locking liquidity in $ERGON comes with a stack of benefits:</p>
                <ul className="benefits-grid">
                    <li><strong>Passive Profit:</strong> Earn from every trade in the pool while the market moves for you.</li>
                    <li><strong>Stabilize the Market:</strong> Your liquidity adds **firepower**, reducing volatility and making the market **bulletproof**.</li>
                    <li><strong>Ride the Wave:</strong> As the Ergon ecosystem grows, **more trades mean more profits**.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* Call to Action */}
            <motion.div
                className="provide-liquidity-cta"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                <h3>Time to Bank</h3>
                <p>Join $ERGON pools, **lock in your liquidity**, and start stacking rewards. It�s time to fuel the future and **make it rain**.</p>
                <a href="https://xeggex.com/pool/XRG_USDT" className="cta-button">Stake Liquidity</a>
            </motion.div>
        </div>
    );
};

export default ProvideLiquidity;
