import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home.tsx';
import GetStarted from './components/GetStarted.tsx';
import JoinGame from './components/JoinGame.tsx';
import Vision from './components/Vision.tsx';
import MineErgon from './components/MineErgon.tsx';
import TradeErgon from './components/TradeErgon.tsx';
import ProvideLiquidity from './components/ProvideLiquidity.tsx';
import HowItWorks from './components/HowItWorks.tsx';
import FAQ from './components/FAQ.tsx';
import ContactUs from './components/ContactUs.tsx';
import Navbar from './components/NavBar.tsx'; // Import the Navbar component
import WhatIsErgon from './components/WhatIsErgon.tsx';
import HoldErgon from './components/HoldErgon.tsx';
import CodeErgon from './components/CodeErgon.tsx';
import Bridge from './components/Bridge.tsx';
import Pulse from './components/Pulse.tsx';
import TellMeMore from './components/TellMeMore.tsx';
import LetsCook from './components/LetsCook.tsx';
import { AppWalletProvider } from './components/AppWalletProvider.tsx'; // Import AppWalletProvider for wallet integration

const App: React.FC = () => {
    const whatIsErgonRef = useRef<HTMLDivElement>(null);
    const getStartedRef = useRef<HTMLDivElement>(null);
    const tellMeMoreRef = useRef<HTMLDivElement>(null);
    const letsCookRef = useRef<HTMLDivElement>(null);
    const joinGameRef = useRef<HTMLDivElement>(null);

    const scrollToWhatIsErgon = () => {
        if (whatIsErgonRef.current) {
            whatIsErgonRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTellMeMore = () => {
        if (tellMeMoreRef.current) {
            tellMeMoreRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToLetsCook = () => {
        if (letsCookRef.current) {
            letsCookRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToGetStarted = () => {
        if (getStartedRef.current) {
            getStartedRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToJoinGame = () => {
        if (joinGameRef.current) {
            joinGameRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <AppWalletProvider> {/* Wrap entire app with AppWalletProvider for Solana wallet context */}
            <Router>
                <Navbar />

                <Routes>
                    {/* Home Page with WhatIsErgon and Join Game */}
                    <Route path="/" element={
                        <div className="App">
                            <Home onWhatIsErgonClick={scrollToWhatIsErgon} />

                            {/* WhatIsErgon section */}
                            <div ref={whatIsErgonRef}>
                                <WhatIsErgon onTellMeMoreClick={scrollToTellMeMore} />
                            </div>

                            {/* TellMeMore section */}
                            <div ref={tellMeMoreRef}>
                                <TellMeMore onLetsCoolClick={scrollToLetsCook} />
                            </div>

                            {/* LetsCook section */}
                            <div ref={letsCookRef}>
                                <LetsCook onGetStartedClick={scrollToGetStarted} />
                            </div>

                            {/* GetStarted section */}
                            <div ref={getStartedRef}>
                                <GetStarted onJoinGameClick={scrollToJoinGame} />
                            </div>

                            {/* Additional sections */}
                            <FAQ />
                            <ContactUs />
                        </div>
                    } />

                    {/* Additional routes */}
                    <Route path="/vision" element={<Vision />} />
                    <Route path="/mine-ergon" element={<MineErgon />} />
                    <Route path="/trade-ergon" element={<TradeErgon />} />
                    <Route path="/hold-ergon" element={<HoldErgon />} />
                    <Route path="/code-ergon" element={<CodeErgon />} />
                    <Route path="/provide-liquidity" element={<ProvideLiquidity />} />
                    <Route path="/bridge" element={<Bridge />} />
                    <Route path="/pulse" element={<Pulse />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                </Routes>
            </Router>
        </AppWalletProvider>
    );
};

export default App;
