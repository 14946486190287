import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import QRCode from 'react-qr-code';
import { useWallet } from '@solana/wallet-adapter-react';
import WalletConnect from './WalletConnect.tsx';
import axios from 'axios';
import '../styles/Burner.css';
import { FaCopy } from 'react-icons/fa';

const Burner: React.FC = () => {
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [timeLeft, setTimeLeft] = useState<number>(900); // 15-minute timer
    const [qrAddress, setQrAddress] = useState<string | null>(null);
    const [transactionVerified, setTransactionVerified] = useState(false);
    const [ergonTxHash, setErgonTxHash] = useState<string | null>(null);
    const [amountReceived, setAmountReceived] = useState<number | null>(null); // Stored as ergoshis
    const [statusMessage, setStatusMessage] = useState<string>("Awaiting transaction...");
    const { publicKey } = useWallet();
    const [txUuid, setTxUuid] = useState<string | null>(null);
    const [copyMessage, setCopyMessage] = useState<string | null>(null);
    const [displayedTimestamp, setDisplayedTimestamp] = useState<number | null>(null);

    // Fetch the next available Ergon address and initialize the transaction
    const fetchNextErgonAddress = useCallback(async () => {
        try {
            const storedTxUuid = localStorage.getItem('txUuid');
            if (storedTxUuid) {
                console.log('Existing transaction found, checking its status.');
                const response = await axios.get(`http://127.0.0.1:5000/api/check-tx/${storedTxUuid}`);
                const { ergonAddress, solanaAddress, status } = response.data;

                // Check if the Solana address associated with the stored transaction matches the current public key
                if (solanaAddress !== publicKey?.toBase58()) {
                    console.log('Solana address has changed. Generating a new transaction.');
                    localStorage.removeItem('txUuid');
                } else if (status !== 'pending') {
                    console.log('Transaction is not in pending status, creating a new one.');
                    localStorage.removeItem('txUuid');
                } else {
                    setWalletAddress(ergonAddress);
                    setQrAddress(`ergon:${ergonAddress}`);
                    setTxUuid(storedTxUuid);
                    return;
                }
            }

            const response = await axios.get('http://127.0.0.1:5000/api/next-address');
            const { address } = response.data;
            setWalletAddress(address);
            setQrAddress(`ergon:${address}`);

            const timestamp = Date.now();
            setDisplayedTimestamp(timestamp);

            const initTxResponse = await axios.post('http://127.0.0.1:5000/api/init-tx', {
                solanaAddress: publicKey?.toBase58(),
                ergonAddress: address
            });

            const uuid = initTxResponse.data.transaction.uuid;
            setTxUuid(uuid);
            localStorage.setItem('txUuid', uuid);
            console.log('Transaction initialized with UUID:', uuid);
        } catch (error) {
            console.error('Error initializing transaction:', error);
        }
    }, [publicKey]);


    useEffect(() => {
        if (publicKey && !walletAddress) {
            fetchNextErgonAddress();
        }
    }, [publicKey, walletAddress, fetchNextErgonAddress]);

    // Polling for transaction status
    useEffect(() => {
        let pollingInterval: NodeJS.Timeout | null = null;

        if (txUuid) {
            console.log("Starting transaction polling...");

            const checkTransaction = async () => {
                try {
                    const response = await axios.get(`http://127.0.0.1:5000/api/check-tx/${txUuid}`);
                    const result = response.data;
                    //console.log("Transaction checked:", result);

                    // If the transaction has been received and confirmed, update the state accordingly
                    if (result && result.status === 'received') {
                        const xrgAmount = result.xrgAmount / 1e8; // Convert ergoshis to full XRG

                        setTransactionVerified(true);
                        setErgonTxHash(result.ergonBurnHash);
                        setAmountReceived(xrgAmount); // Update amount in full XRG
                        setStatusMessage(`${xrgAmount.toFixed(8)} $XRG received. Sending to the burner...`);

                        // Stop the timer
                        setTimeLeft(0);
                    }

                    // If the XRG is burnt and ERGON is minted, update the final status
                    if (result.status === 'burnt') {
                        setStatusMessage("XRG burnt, let's make Solami magic...");
                    }

                    if (result.status === 'minted') {
                        setStatusMessage("ERGON minted, check your wallet!");
                        clearInterval(pollingInterval!); // Stop polling when minted
                    }
                } catch (error) {
                    console.error('Error checking transaction status:', error);
                }
            };

            // Poll every 5 seconds
            pollingInterval = setInterval(checkTransaction, 5000);
        }

        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [txUuid, transactionVerified]);

    // Timer logic
    useEffect(() => {
        if (transactionVerified) {
            setTimeLeft(0); // Stop timer if transaction is verified
        } else if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft, transactionVerified]);

    // Copy wallet address to clipboard
    const copyToClipboard = () => {
        if (walletAddress) {
            navigator.clipboard.writeText(walletAddress);
            setCopyMessage("Address copied to clipboard!");
            setTimeout(() => setCopyMessage(null), 2000);
        }
    };

    return (
        <div className="burner-container">
            <WalletConnect />
            {publicKey && (
                <motion.div className="burner-content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <h3 className="burner-header">Burn $XRG to receive $ERGON</h3>
                    <p className="cypherpunk-text">$ERGON will be sent to your Solana wallet {publicKey.toBase58()}</p>

                    <div className="qr-code-section">
                        {walletAddress && (
                            <div className="qr-code-container">
                                <QRCode value={qrAddress || ''} size={150} />
                                <p className="send-xrg-text">Send $XRG to this Ergon address:</p>
                                <div className="address-container">
                                    <p className="wallet-address">{walletAddress}</p>
                                    <FaCopy className="copy-icon" onClick={copyToClipboard} />
                                </div>
                                {copyMessage && (
                                    <motion.p className="copy-feedback" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                        {copyMessage}
                                    </motion.p>
                                )}
                                {timeLeft > 0 && (
                                    <p className="timer">Time left: {Math.floor(timeLeft / 60)}:{('0' + (timeLeft % 60)).slice(-2)} minutes</p>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Dynamic Status Messages */}
                    <div className="status-section">
                        <motion.p className="status-message" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                            {statusMessage}
                        </motion.p>
                    </div>

                    {transactionVerified && amountReceived && (
                        <motion.p className="confirmation-text" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                            {statusMessage === "XRG burnt, let's make Solami magic..."
                                ? "ERGON minted, check your wallet!"
                                : `Transaction confirmed! Ergon TX: ${ergonTxHash}.`}
                        </motion.p>
                    )}
                </motion.div>
            )}
        </div>
    );
};

export default Burner;
