import React from 'react';
import { motion } from 'framer-motion';
import '../styles/CodeErgon.css';

const CodeErgon: React.FC = () => {
    return (
        <div className="code-ergon-container">
            {/* Animated Background Elements */}
            <div className="background-code"></div>

            {/* Header Section */}
            <motion.h1
                className="code-ergon-header"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                Code Ergon: The Chain for Visionaries and Degens
            </motion.h1>

            {/* Introduction Section */}
            <motion.p
                className="code-ergon-intro"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 1 }}
            >
                $ERGON isn�t just another blockchain�it�s the **weapon of choice** for developers who want to **build fast, scale harder**, and deploy next-gen dApps. Powered by **Bitcoin�s core** but engineered to deliver **next-level features**, it�s time to step up and start coding the **future of decentralized finance**.
            </motion.p>

            <motion.div className="divider" />

            {/* Section: Bitcoin-Based Codebase */}
            <motion.section
                className="codebase-section"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <h2>Bitcoin Core with Modern Firepower</h2>
                <p>
                    Built on **Bitcoin�s core** and boosted by **BCH and XEC**, $ERGON is the **next-gen chain**. It�s not just scalable�it�s **unshakable**, with low fees and big blocks. Time to break limits.
                </p>
                <ul className="code-benefits">
                    <li><strong>Big Block Scaling:</strong> Blow past transaction limits with **massive throughput**, supporting high-frequency ops.</li>
                    <li><strong>Minimal Fees:</strong> Stay lean and mean with **ultra-low transaction costs** that make high-volume trading a breeze.</li>
                    <li><strong>Dev-Friendly:</strong> Ergon�s a **dream playground** for developers, built for speed, scale, and zero-compromise performance.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* Section: Token and NFT Protocols */}
            <motion.section
                className="token-nft-section"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7, duration: 1 }}
            >
                <h2>Token and NFT Protocols: Build and Conquer</h2>
                <p>
                    Build **everything**, from **fungible tokens** to the most complex **on-chain NFTs**. $ERGON�s low fees make token and NFT creation a **no-brainer**�it�s time to unleash your digital assets:
                </p>
                <ul className="token-nft-list">
                    <li><strong>Mint and Dominate:</strong> Issue utility tokens, govern DAOs, or back real-world assets, all while keeping **fees dead-low**.</li>
                    <li><strong>NFT Power:</strong> Turn your game or marketplace into an **NFT empire** with instant trades and **no gas pain**.</li>
                    <li><strong>Smart Assets:</strong> Use programmable covenants to create **on-chain contracts**, unlocking a future of **programmable finance**.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* Section: Chronik Index */}
            <motion.section
                className="chronik-index-section"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 1 }}
            >
                <h2>Chronik Index: The Speed Demon of Blockchain Data</h2>
                <p>
                    With **Chronik**, you�re getting real-time, blazing-fast access to **Ergon�s data**. Whether you�re querying transactions or blocks, **Chronik�s your weapon** for pulling data faster than anyone else:
                </p>
                <ul className="chronik-benefits">
                    <li><strong>Instant Queries:</strong> Pull blockchain data in real time�no lag, no hassle, just **straight power**.</li>
                    <li><strong>Scalable by Design:</strong> As $ERGON grows, Chronik scales with it�ready to handle **anything** you throw at it.</li>
                    <li><strong>Built for Developers:</strong> Forget about third-party services�**Chronik�s native power** gives you all the data you need.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* Section: Covenants */}
            <motion.section
                className="covenants-section"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                <h2>Covenants: The Ultimate On-Chain Control</h2>
                <p>
                    Forget basic transactions. With **Covenants**, you�re coding **custom rules** right into the transaction itself�time locks, escrow, **automated finance**�you control it all:
                </p>
                <ul className="covenants-benefits">
                    <li><strong>Programmable Money:</strong> Set rules for your tokens�**auto-execute**, **auto-lock**, **auto-everything**.</li>
                    <li><strong>DeFi on Steroids:</strong> Create **complex financial tools**�automated trading, decentralized insurance, and beyond.</li>
                    <li><strong>Flexibility and Security:</strong> Lock down your transactions with **rock-solid logic**. You call the shots.</li>
                </ul>
            </motion.section>

            <motion.div className="divider" />

            {/* Call to Action */}
            <motion.div
                className="code-ergon-cta"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.3, duration: 1 }}
            >
                <h3>Ready to Code the Future?</h3>
                <p>
                    Start coding on $ERGON today and build the **next-gen decentralized applications**. Let�s create, deploy, and dominate the space with **covenants, tokens, and NFTs**.
                </p>
                <a href="https://github.com/Ergon-moe/" className="cta-button">Start Building</a>
            </motion.div>
        </div>
    );
};

export default CodeErgon;
